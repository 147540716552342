.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

@keyframes blink {
  0% {
         background-color: rgba(255,0,0,1)
  }
  50% {
         background-color: rgba(255,0,0,0.5)
  }
  100% {
         background-color: rgba(255,0,0,1)
  }
}
@-webkit-keyframes blink {
  0% {
         background-color: rgba(255,0,0,1)
  }
  50% {
         background-color: rgba(255,0,0,0.5)
  }
  100% {
         background-color: rgba(255,0,0,1)
  }
}

.alarm {
  -moz-transition:all 0.5s ease-in-out;
  -webkit-transition:all 0.5s ease-in-out;
  -o-transition:all 0.5s ease-in-out;
  -ms-transition:all 0.5s ease-in-out;
  transition:all 0.5s ease-in-out;
  -moz-animation:blink normal 1.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation:blink normal 1.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation:blink normal 1.5s infinite ease-in-out;
  /* IE */
  animation:blink normal 1.5s infinite ease-in-out;
  /* Opera */
}

.checked {
  background-color: rgba(0, 200, 0, 0.5);
}

.normal {
  background-color: rgba(255, 255, 255, 0.5)
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

input
{
  background-color:white;
  border:none;
  border-bottom: 1px solid lightgray;
}
